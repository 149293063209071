/* Navbar.css */

.navbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 1000;
  }
  
  .navbar-container {
    width: 100%;
    margin: 0;
    padding: 0.5rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  width: 150px;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.column-layout {
  display: flex;
  flex-direction: column;
}

.dropdown-link {
  padding: 10px 20px;
  text-align: left;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.dropdown-link:hover {
  background-color: #f0f0f0;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.transition-transform {
  transition: transform 0.3s ease;
}

  
  .logo {
    font-size: 24px;
    color: white;
  }
  
  .menu-toggle {
    display: none; /* Hide by default on larger screens */
    cursor: pointer;
  }
  
  .icon {
    font-size: 24px;
  }
  
  .navbar-menu {
    position: relative;
    left: 6%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .menu-link {
    padding: 5px 0px;
    margin: 0 15px;
    text-decoration: none;
    color: #646A73;
    /* margin-right: 10px; */
  }

  .menu-link:hover {
    border-bottom: 3px solid #EE009D; /* Border line when hovering over navigation links */
    color: #2AA100;
  }
  
  
.menu-link.active,
.menu-link.active:hover {
  color: #2AA100; /* Change the color to the desired color */
  border-bottom: 3px solid #EE009D;;
  margin: 0 1rem; /* Adjust the margin as needed */
}

  /* Media query for smaller screens */
  @media only screen and (max-width: 767px) {
    .menu-toggle {
      display: block; /* Show toggle button */
    }

    .navbar-container {
       width: 100%;
       padding: 1rem;
       display: flex;
       justify-content: space-between;
       align-items: center;
     }

     .icon{
      margin-right: 1rem;
     }
  
    .navbar-menu {
      display: none; /* Hide menu by default on smaller screens */
      position: absolute;
      align-items: start;
      padding: 1rem;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #FFFFFF;
      flex-direction: column;
    }
    .menu-link.active,
.menu-link.active:hover{
  margin: 0 1rem; 
  color: #2AA100; /* Change the color to the desired color */
  border-bottom: 3px solid #EE009D;
  align-items: start;
}
  
    .navbar-menu.open {
      display: flex; /* Show menu when open */
      animation: slideDown 1s forwards; /* Add slide down animation */
    }
  
    @keyframes slideDown {
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    .menu-link {
      padding: 10px 0px;
      margin: 0 15px;
      text-align: left;
    }
  }
